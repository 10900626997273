export function RoadmapDuoIcon({
  className,
  color = "currentColor",
}: {
  className?: string;
  color?: string;
}) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={
        {
          "--icon-color": color,
          "--icon-color-dark": `color-mix(in srgb, var(--icon-color) 35%, black)`,
        } as React.CSSProperties
      }
    >
      <g clipPath="url(#clip0_13914_96255)">
        <path
          d="M0.985046 5.35713C0.985046 4.83596 1.2795 4.35953 1.74565 4.12646L7.70803 1.14526C8.0954 0.951579 8.55134 0.95158 8.93871 1.14526L15.0464 4.19909C15.4337 4.39277 15.8897 4.39277 16.277 4.19909L21.0087 1.83323C21.9236 1.3758 23 2.04106 23 3.0639V18.333C23 18.8542 22.7056 19.3306 22.2394 19.5637L16.277 22.5449C15.8897 22.7386 15.4337 22.7386 15.0464 22.5449L8.93871 19.4911C8.55134 19.2974 8.0954 19.2974 7.70803 19.4911L2.97632 21.8569C2.06146 22.3144 0.985046 21.6491 0.985046 20.6263V5.35713Z"
          fill="var(--icon-color-dark)"
        />
        <path
          d="M8.32337 5.42405V15.5142M15.6617 8.17592V18.2661M16.277 22.5449L22.2394 19.5637C22.7056 19.3306 23 18.8542 23 18.333V3.0639C23 2.04106 21.9236 1.3758 21.0087 1.83323L16.277 4.19909C15.8897 4.39277 15.4337 4.39277 15.0464 4.19909L8.93871 1.14526C8.55134 0.95158 8.0954 0.951579 7.70803 1.14526L1.74565 4.12646C1.2795 4.35953 0.985046 4.83596 0.985046 5.35713V20.6263C0.985046 21.6491 2.06146 22.3144 2.97632 21.8569L7.70803 19.4911C8.0954 19.2974 8.55134 19.2974 8.93871 19.4911L15.0464 22.5449C15.4337 22.7386 15.8897 22.7386 16.277 22.5449Z"
          stroke="var(--icon-color)"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_13914_96255">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
