export function ClipboardDuoIcon({
  className,
  color = "currentColor",
}: {
  className?: string;
  color?: string;
}) {
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={
        {
          "--icon-color": color,
          "--icon-color-dark": `color-mix(in srgb, var(--icon-color) 35%, black)`,
        } as React.CSSProperties
      }
    >
      <path
        d="M18 4H6C4.89543 4 4 4.89543 4 6V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V18V10V6C20 4.89543 19.1046 4 18 4Z"
        fill="var(--icon-color-dark)"
      />
      <path
        d="M20 10V6C20 4.89543 19.1046 4 18 4H6C4.89543 4 4 4.89543 4 6V20C4 21.1046 4.89543 22 6 22H18C19.1046 22 20 21.1046 20 20V18"
        stroke="var(--icon-color)"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M15 2H9C8.44772 2 8 2.44772 8 3V5C8 5.55228 8.44772 6 9 6H15C15.5523 6 16 5.55228 16 5V3C16 2.44772 15.5523 2 15 2Z"
        fill="var(--icon-color-dark)"
        stroke="var(--icon-color)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 14H11"
        stroke="var(--icon-color)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 10L11 14L15 18"
        stroke="var(--icon-color)"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
